var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Box from 'elements/Box';
export default function FormCredentialsSent(props) {
    var logo = props.logo, setState = props.setState, setEmail = props.setEmail, setLoginData = props.setLoginData, loginDataDefault = props.loginDataDefault;
    return (_jsxs(Box, __assign({ id: 'loginBox' }, { children: [_jsxs(Link, __assign({ component: "button", variant: "subtitle2", underline: "none", onClick: function () {
                    setEmail('');
                    setLoginData(loginDataDefault);
                    setState('login');
                }, sx: { display: 'flex', alignItems: 'center', fontSize: 'small', justifyContent: 'start', columnGap: '5px', padding: '0px 0px 10px 0px' } }, { children: [_jsx(ArrowBack, { sx: { fontSize: 'small' } }), "Volver"] })), _jsx("img", { className: 'loginLogo', src: logo }), _jsx("p", { children: "La informaci\u00F3n para recuperar el acceso fue enviada. Por favor, revisa el correo." })] })));
}
