var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ProjectConfig } from 'Global';
import { useSnackbar } from 'notistack';
import 'assets/css/main.css';
import ListPagination from 'elements/ListPagination';
import RefundEditor from './RefundEditor';
export default function RefundsList(props) {
    var _this = this;
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    var open = props.open, setOpen = props.setOpen, store_id = props.store_id, getDetails = props.getDetails;
    var _b = useState([]), list = _b[0], setList = _b[1];
    var _c = useState({
        total_pages: 1,
        total_items: 0,
    }), pagination = _c[0], setPagination = _c[1];
    var queryParams = new URLSearchParams(window.location.search);
    var currentPage = parseInt(localStorage.getItem('page') || "1");
    var _d = useState(currentPage), page = _d[0], setPage = _d[1];
    /*=========================================================
    GET RECORDS
    =========================================================*/
    useEffect(function () {
        if (open) {
            getPayments();
        }
    }, [page, open]);
    var getPayments = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            axios.get(ProjectConfig.api_url + "/v2/payments/StorePaymentList", {
                params: {
                    store_id: store_id
                },
            })
                .then(function (response) {
                console.log("Payments res", response.data);
                setList(response.data.list);
                setPagination(response.data.pagination);
            })
                .catch(function (err) {
                console.log(err.response.data);
            })
                .finally(function () {
            });
            return [2 /*return*/];
        });
    }); };
    var _e = useState({}), activeItem = _e[0], setActiveItem = _e[1];
    var _f = useState(false), openEditor = _f[0], setOpenEditor = _f[1];
    return (_jsxs(Dialog, __assign({ open: open, onClose: function () { return setOpen(false); }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", PaperProps: { sx: { borderRadius: "20px" } } }, { children: [_jsx("div", __assign({ className: "DialogContainer" }, { children: _jsxs("div", __assign({ className: "ModuleWrapper" }, { children: [_jsx("div", __assign({ className: "boxContainerHeader" }, { children: _jsxs("div", __assign({ className: "dialog_container_title" }, { children: [_jsx("h2", __assign({ className: "dialog_title" }, { children: "Payments" })), _jsx("h3", __assign({ className: "dialog_subtitle" }, { children: "List" }))] })) })), _jsx("div", __assign({ id: 'MasterLog', className: 'ModuleContainer' }, { children: _jsx("div", __assign({ className: "ModuleWrapper" }, { children: _jsx("div", __assign({ className: 'boxContainerIn' }, { children: _jsxs("div", { children: [_jsx(TableContainer, __assign({ sx: { width: "100%" } }, { children: _jsxs(Table, __assign({ sx: { width: "100%" } }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "Date" })), _jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "Amount" })), _jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "Status" })), _jsx(TableCell, { sx: { color: '#a8a8a8', borderBottom: "none" } })] }) }), _jsx(TableBody, { children: list.map(function (item) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: item.created_at }), _jsx(TableCell, { children: item.amount }), _jsx(TableCell, { children: item.status }), _jsx(TableCell, { children: item.status === 'Paid' && (_jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () {
                                                                                setActiveItem(item);
                                                                                setOpenEditor(true);
                                                                            } }, { children: "Refund" }))) })] })); }) })] })) })), _jsx(ListPagination, { pagination: pagination, page: page, onChange: function (event, value) {
                                                    setPage(value);
                                                }, label: "Logs", style: { marginTop: "20px", alignSelf: "center" } })] }) })) })) }))] })) })), _jsx(RefundEditor, { open: openEditor, setOpen: setOpenEditor, item: activeItem, store_id: store_id, getPayments: getPayments, getDetails: getDetails })] })));
}
;
