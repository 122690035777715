var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Cookies from 'universal-cookie';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { TextField, Link } from '@mui/material';
import Box from 'elements/Box';
var cookies = new Cookies();
export default function FormLogin(props) {
    var logo = props.logo, setLoading = props.setLoading, setState = props.setState, email = props.email, setEmail = props.setEmail, loginData = props.loginData, setLoginData = props.setLoginData, loadingButton = props.loadingButton, setLoadingButton = props.setLoadingButton, setOpenRegister = props.setOpenRegister, sign_in = props.sign_in;
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    /*============================================= */
    /*================================================
    SIGN IN (SUBMIT)
    ================================================*/
    var handleSubmit = function (event) {
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        setLoadingButton(true);
        sign_in();
    };
    /*================================================
    ================================================*/
    return (_jsxs(Box, __assign({ id: 'loginBox', className: 'intent-auth_component' }, { children: [_jsx("img", { className: 'loginLogo', src: logo }), _jsx("h1", __assign({ className: "intent-auth_heading text-color-darkpurple" }, { children: "Log into my account" })), _jsxs("form", __assign({ onSubmit: handleSubmit, className: 'form' }, { children: [_jsx(TextField, { required: true, fullWidth: true, id: "email", label: "Email Address", name: "email", value: loginData.email, onChange: function (e) {
                            setLoginData(__assign(__assign({}, loginData), { email: e.target.value }));
                            setEmail(e.target.value);
                        }, type: "text", autoComplete: "email", autoFocus: true }), _jsx(TextField, { required: true, fullWidth: true, name: "password", label: "Password", type: "password", id: "password", autoComplete: "current-password", value: loginData.password, onChange: function (e) { return setLoginData(__assign(__assign({}, loginData), { password: e.target.value })); } }), _jsx(LoadingButton, __assign({ size: "small", type: "submit", loading: loadingButton, fullWidth: true, variant: "contained", sx: { fontSize: '13px', padding: '10px 0px' } }, { children: "Log In" })), _jsxs("div", __assign({ className: 'intent-auth_links' }, { children: [_jsx(Link, __assign({ component: "button", variant: "body2", onClick: function () {
                                    setOpenRegister(true);
                                } }, { children: "Don\u2019t Have An Account?" })), _jsx(Link, __assign({ component: "button", variant: "body2", onClick: function () {
                                    setState('forgotPass');
                                } }, { children: "Forgot Password?" }))] }))] }))] })));
}
