var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Chip, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ProjectConfig } from 'Global';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import 'assets/css/main.css';
import UsersEditor from './UsersEditor';
import EmptyElement from 'elements/EmptyElement';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
export default function UsersList(props) {
    var _a = props.company_id, company_id = _a === void 0 ? null : _a, _b = props.is_popup, is_popup = _b === void 0 ? false : _b;
    var _c = useSnackbar(), enqueueSnackbar = _c.enqueueSnackbar, closeSnackbar = _c.closeSnackbar;
    var setLoading = props.setLoading;
    useEffect(function () {
        setLoading(false);
    }, []);
    var _d = useState([]), user_list = _d[0], setUserList = _d[1];
    var _e = useState(0), total_pages = _e[0], setTotalPages = _e[1];
    var queryParams = new URLSearchParams(window.location.search);
    var currentPage = parseInt(localStorage.getItem('page') || "1");
    var _f = useState(currentPage), page = _f[0], setPage = _f[1];
    var category = queryParams.get('category');
    var _g = useState(0), refreshList = _g[0], setRefreshList = _g[1];
    var _h = useState(false), openEditor = _h[0], setOpenEditor = _h[1];
    useEffect(function () {
        setLoading(true);
        loadList();
    }, [page]);
    /*=========================================================
    LOAD LIST
    =========================================================*/
    var loadList = function () {
        setLoading(true);
        axios.get(ProjectConfig.api_url + "/v2/users/list", {
            headers: {
                'ltkn': localStorage.getItem('ltkn')
            },
            params: {
                page: page,
                role_id: 2,
                company_id: company_id
            }
        })
            .then(function (response) {
            console.log("User List ", response.data);
            setUserList(response.data.list);
            setTotalPages(response.data.total_pages);
            setLoading(false);
        })
            .catch(function (err) {
            console.log(err.response.data);
        })
            .finally(function () {
            setLoading(false);
        });
        localStorage.setItem('page', page.toString());
    };
    /*=========================================================*/
    /*=========================================================
    DELETE ITEM
    =========================================================*/
    var handleDelete = function (user_id) {
        setActiveItem(user_id);
        setOpenAlert(true);
    };
    var deleteItem = function () {
        var body = {
            user_id: activeItem
        };
        axios.post(ProjectConfig.api_url + "/v2/users/delete", body)
            .then(function (response) {
            console.log(response.data);
            if (response.data.status === "success") {
                enqueueSnackbar('Usuario eliminado con éxito', { variant: 'success' });
                loadList();
            }
        })
            .catch(function (err) {
            console.log(err.data);
        })
            .then(function () {
            setOpenAlert(false);
        });
    };
    /*=========================================================*/
    // call global snackbar
    var _j = useState(false), openAlert = _j[0], setOpenAlert = _j[1];
    var _k = useState(null), activeItem = _k[0], setActiveItem = _k[1];
    return (_jsxs("div", __assign({ id: 'UsersList', className: 'ModuleContainer' }, { children: [_jsxs("div", __assign({ className: "ModuleWrapper" }, { children: [_jsxs("div", __assign({ className: "boxContainerHeader" }, { children: [_jsx("div", __assign({ className: "boxContainerTitle" }, { children: _jsx("h1", { children: "Admin Roles" }) })), _jsx("div", __assign({ className: "boxContainerActions" }, { children: _jsx(Button, __assign({ variant: "outlined", color: "info", onClick: function () {
                                        setActiveItem(null);
                                        setOpenEditor(true);
                                    }, startIcon: _jsx(AddIcon, {}), className: "btnList" }, { children: "New Admin" })) }))] })), _jsx("div", __assign({ id: 'UsersListIn' }, { children: _jsxs("div", { children: [user_list.length !== 0 ? (_jsx(TableContainer, { children: _jsxs(Table, __assign({ "aria-label": "sticky table" }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "User" })), _jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "Email" })), _jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "Status" })), _jsx(TableCell, { sx: { color: '#a8a8a8', borderBottom: "none" } })] }) }), _jsx(TableBody, { children: user_list.map(function (user, i) { return (_jsxs(TableRow, __assign({ hover: true, role: "checkbox" }, { children: [_jsxs(TableCell, { children: [user.name, " ", user.last_name] }), _jsx(TableCell, { children: user.email }), _jsx(TableCell, { children: _jsx(Chip, { label: user.is_active ? "Active" : "Inactive", color: user.is_active ? "primary" : "error", variant: "outlined", size: 'small' }) }), _jsx(TableCell, __assign({ align: "right" }, { children: _jsx(IconButton, __assign({ color: "primary", onClick: function () {
                                                                    setActiveItem(user.user_id);
                                                                    setOpenEditor(true);
                                                                } }, { children: _jsx(MoreHorizIcon, {}) })) }))] }), i)); }) })] })) })) : (_jsx("div", __assign({ className: "emptyList" }, { children: _jsx(EmptyElement, { title: "No users", subtitle: "Start by adding a new user" }) }))), total_pages > 1 && (_jsx(Pagination, { count: total_pages, page: page, onChange: function (event, value) {
                                        setPage(value);
                                    }, color: "primary", style: { "marginTop": "20px", "alignSelf": "center" } }))] }) }))] })), _jsx(AlertDialog, { open: openAlert, setOpen: setOpenAlert, subtitle: "\u00BFEst\u00E1 seguro que desea eliminar este usuario?", send: deleteItem }), _jsx(UsersEditor, { setLoading: setLoading, openEditor: openEditor, setOpenEditor: setOpenEditor, user_id: activeItem, loadList: loadList, company_id: company_id })] })));
}
;
