var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField, Link, } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Box from 'elements/Box';
import { ProjectConfig } from 'Global';
export default function FormForgotPass(props) {
    var loadingButton = props.loadingButton, logo = props.logo, setLoading = props.setLoading, setState = props.setState, email = props.email, setEmail = props.setEmail;
    /*================================================
    SNACKBAR FUNCTION FOR NOTIFICATIONS
    ================================================*/
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    /*============================================= */
    /*================================================
    FORGOT PASSWORD (SUBMIT)
    ================================================*/
    var handleSubmit = function (event) {
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        setLoading(true);
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "forgot_password",
                email: data.get('email'),
            },
        })
            .then(function (response) {
            if (response.data.status === "success") {
                enqueueSnackbar(response.data.message, { variant: 'success' });
                setState('credentialsSent');
            }
            else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        })
            .catch(function (error) {
            enqueueSnackbar(error.toString(), { variant: 'error' });
        })
            .finally(function () {
            setLoading(false);
        });
        setState('credentialsSent'); // REMOVE THIS LINE
    };
    /*================================================
    ================================================*/
    return (_jsxs(Box, __assign({ id: 'loginBox' }, { children: [_jsxs(Link, __assign({ component: "button", variant: "subtitle2", underline: "none", onClick: function () {
                    setEmail('');
                    setState('login');
                }, sx: { display: 'flex', alignItems: 'center', fontSize: 'small', justifyContent: 'start', columnGap: '5px', padding: '0px 0px 10px 0px' } }, { children: [_jsx(ArrowBack, { sx: { fontSize: 'small' } }), "Volver"] })), _jsxs("form", __assign({ onSubmit: handleSubmit, className: 'form' }, { children: [_jsx(TextField, { required: true, fullWidth: true, id: "email", label: "Correo electr\u00F3nico", name: "email", type: "text", autoComplete: "email", autoFocus: true, value: email, onChange: function (e) { return setEmail(e.target.value); } }), _jsx(LoadingButton, __assign({ size: "small", type: "submit", loading: loadingButton, fullWidth: true, variant: "contained", sx: { fontSize: '13px', padding: '10px 0px' } }, { children: "Enviar" }))] }))] })));
}
