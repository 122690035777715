var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
/**
 * DateRange
 *  - This component renders a two-step date range picker
 * @param props
 * @returns
 */
export default function DateRange(props) {
    var _a = props.label, label = _a === void 0 ? '' : _a, _b = props.placeholder, placeholder = _b === void 0 ? '' : _b, _c = props.value, value = _c === void 0 ? {} : _c, _d = props.format, format = _d === void 0 ? 'MM/dd/yyyy' : _d, _e = props.onChange, onChange = _e === void 0 ? function () { } : _e, _f = props.disabled, disabled = _f === void 0 ? false : _f, _g = props.required, required = _g === void 0 ? false : _g, _h = props.error, error = _h === void 0 ? false : _h, _j = props.helperText, helperText = _j === void 0 ? '' : _j, _k = props.fullWidth, fullWidth = _k === void 0 ? false : _k, _l = props.inputProps, inputProps = _l === void 0 ? {} : _l, _m = props.InputProps, InputProps = _m === void 0 ? {} : _m, _o = props.InputLabelProps, InputLabelProps = _o === void 0 ? {} : _o, _p = props.variant, variant = _p === void 0 ? 'standard' : _p, _q = props.size, size = _q === void 0 ? 'medium' : _q, _r = props.color, color = _r === void 0 ? 'primary' : _r, _s = props.className, className = _s === void 0 ? '' : _s, _t = props.style, style = _t === void 0 ? {} : _t, _u = props.sx, sx = _u === void 0 ? {} : _u, _v = props.variantDatePicker, variantDatePicker = _v === void 0 ? 'two-steps' : _v, otherProps = __rest(props, ["label", "placeholder", "value", "format", "onChange", "disabled", "required", "error", "helperText", "fullWidth", "inputProps", "InputProps", "InputLabelProps", "variant", "size", "color", "className", "style", "sx", "variantDatePicker"]);
    var _w = useState(false), isOpenStartDatePicker = _w[0], setIsOpenStartDatePicker = _w[1];
    var _x = useState(false), isOpenEndDatePicker = _x[0], setIsOpenEndDatePicker = _x[1];
    var _y = useState(null), startDate = _y[0], setStartDate = _y[1];
    var _z = useState(null), endDate = _z[0], setEndDate = _z[1];
    var _0 = useState(''), dateRangeTextValue = _0[0], setDateRangeTextValue = _0[1];
    useEffect(function () {
        if (startDate && endDate) {
            var start = moment(startDate).format(format);
            var end = moment(endDate).format(format);
            setDateRangeTextValue("".concat(start, " - ").concat(end));
        }
        else {
            // show empty format if startDate or endDate is null
            setDateRangeTextValue("".concat(format, " - ").concat(format));
        }
    }, [startDate, endDate]);
    /* VALIDATE */
    useEffect(function () {
        if (checkIsDateRange(value)) {
            setStartDate(value.startDate);
            setEndDate(value.endDate);
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
    }, [value]);
    var checkIsDate = function (date) {
        return date && new Date(date).toString() !== 'Invalid Date';
    };
    var checkIsDateRange = function (dateRange) {
        var isValid = false;
        if (!dateRange)
            return isValid;
        if (!dateRange.startDate)
            return isValid;
        if (!dateRange.endDate)
            return isValid;
        if (!checkIsDate(dateRange.startDate))
            return isValid;
        if (!checkIsDate(dateRange.endDate))
            return isValid;
        if (dateRange.startDate > dateRange.endDate)
            return isValid;
        isValid = true;
        return isValid;
    };
    /* HANDLE CONFIRM AND CANCEL */
    var handleCancel = function () {
        setStartDate(null);
        setEndDate(null);
    };
    var handleConfirm = function () {
        var dataRange = {
            startDate: startDate,
            endDate: endDate,
        };
        if (checkIsDateRange(dataRange) && startDate && endDate) {
            onChange({
                startDate: new Date(startDate),
                endDate: new Date(endDate),
            });
        }
        else {
            handleCancel();
        }
    };
    /* RENDER */
    function renderStartDatePicker(is_two_steps_variant) {
        return (_jsx(StaticDatePicker, { value: startDate, onChange: function (date) {
                if (checkIsDate(date)) {
                    setStartDate(date);
                    if (is_two_steps_variant) {
                        setIsOpenStartDatePicker(false);
                        setIsOpenEndDatePicker(true);
                    }
                }
            }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { autoFocus: true, margin: "normal", variant: "standard", helperText: "Choose start date" }))); }, showToolbar: false, componentsProps: {
                actionBar: {
                    actions: []
                }
            } }));
    }
    ;
    var renderEndDatePicker = function (is_two_steps_variant) {
        return (_jsx(StaticDatePicker, __assign({ value: endDate, onChange: function (date) {
                if (checkIsDate(date)) {
                    setEndDate(date);
                    if (is_two_steps_variant) {
                        setIsOpenEndDatePicker(false);
                        handleConfirm();
                    }
                }
            }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { autoFocus: true, margin: "normal", variant: "standard", helperText: "Choose end date" }))); }, showToolbar: false, componentsProps: {
                actionBar: {
                    actions: []
                }
            } }, ((checkIsDate(startDate)) ?
            { minDate: moment(startDate) } :
            {}))));
    };
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { id: "date", className: className, label: label, placeholder: placeholder, value: dateRangeTextValue, fullWidth: fullWidth, variant: variant, size: size, color: color, style: style, sx: sx, disabled: disabled, required: required, error: error, helperText: helperText, inputProps: inputProps, InputProps: InputProps, InputLabelProps: InputLabelProps, onClick: function () {
                    setIsOpenStartDatePicker(true);
                } }), _jsxs(Dialog, __assign({ open: isOpenStartDatePicker, onClose: function () {
                    setIsOpenStartDatePicker(false);
                } }, (variantDatePicker === 'side-by-side' ? { maxWidth: 'md' } : {}), { children: [_jsxs(DialogTitle, { children: [variantDatePicker === 'two-steps' && (_jsx(_Fragment, { children: "Choose start date" })), variantDatePicker === 'side-by-side' && (_jsx(_Fragment, { children: "Choose start and end date" }))] }), variantDatePicker === 'two-steps' && (_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterMoment }, { children: renderStartDatePicker(true) }))), variantDatePicker === 'side-by-side' && (_jsxs("div", __assign({ className: 'input_row' }, { children: [_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterMoment }, { children: renderStartDatePicker(false) })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterMoment }, { children: renderEndDatePicker(false) }))] }))), variantDatePicker === 'side-by-side' && (_jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () {
                                    setIsOpenStartDatePicker(false);
                                    handleCancel();
                                } }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: function () {
                                    setIsOpenStartDatePicker(false);
                                    handleConfirm();
                                } }, { children: "Confirm" }))] }))] })), _jsxs(Dialog, __assign({ open: isOpenEndDatePicker, onClose: function () {
                    setIsOpenEndDatePicker(false);
                } }, { children: [_jsx(DialogTitle, { children: " Choose end date " }), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterMoment }, { children: renderEndDatePicker(true) }))] }))] }));
}
;
