var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import goalStep1_1 from './goal_step1_1.svg';
import goalStep1_2 from './goal_step1_2.svg';
import { Tooltip } from '@mui/material';
var goals = [
    10000,
    20000,
    30000,
    40000,
    50000,
];
var stepPositions = [
    goals[0] / goals[goals.length - 1] * 100,
    goals[1] / goals[goals.length - 1] * 100,
    goals[2] / goals[goals.length - 1] * 100,
    goals[3] / goals[goals.length - 1] * 100,
    goals[4] / goals[goals.length - 1] * 100,
];
export default function GoalProgress(props) {
    var value = props.value;
    //check if values is near which goal
    var goal = 0;
    var label = 'First';
    if (value >= goals[0] && value < goals[1]) {
        goal = 1;
        label = 'Second';
    }
    if (value >= goals[1] && value < goals[2]) {
        goal = 2;
        label = 'Third';
    }
    if (value >= goals[2] && value < goals[3]) {
        goal = 3;
        label = 'Fourth';
    }
    if (value >= goals[3] && value < goals[4]) {
        goal = 4;
        label = 'Fifth';
    }
    var currentPercent = value / goals[goals.length - 1] * 100;
    return (_jsxs("div", __assign({ id: "GoalProgressBar" }, { children: [_jsx("span", __assign({ className: 'upTitle' }, { children: "Goal Progress Bar" })), _jsxs("h2", __assign({ className: 'progressBarTitle' }, { children: ["$", goals[goal] - value, " To ", label, " Goal"] })), _jsx("div", __assign({ className: "ProgressBar" }, { children: _jsxs(ProgressBar, __assign({ percent: currentPercent, filledBackground: "linear-gradient(to right, #8571FF, #8571FF)", stepPositions: stepPositions }, { children: [_jsx(Step, __assign({ transition: "scale" }, { children: function (_a) {
                                var accomplished = _a.accomplished;
                                return (_jsx(Tooltip, __assign({ title: "$".concat(goals[0].toLocaleString()), componentsProps: { tooltip: { sx: { bgcolor: "#7963ff", color: "white" } } } }, { children: _jsx("img", { width: "30", src: !accomplished ? goalStep1_1 : goalStep1_2 }) })));
                            } })), _jsx(Step, __assign({ transition: "scale" }, { children: function (_a) {
                                var accomplished = _a.accomplished;
                                return (_jsx(Tooltip, __assign({ title: "$".concat(goals[1].toLocaleString()), componentsProps: { tooltip: { sx: { bgcolor: "#7963ff", color: "white" } } } }, { children: _jsx("img", { width: "30", src: !accomplished ? goalStep1_1 : goalStep1_2 }) })));
                            } })), _jsx(Step, __assign({ transition: "scale" }, { children: function (_a) {
                                var accomplished = _a.accomplished;
                                return (_jsx(Tooltip, __assign({ title: "$".concat(goals[2].toLocaleString()), componentsProps: { tooltip: { sx: { bgcolor: "#7963ff", color: "white" } } } }, { children: _jsx("img", { width: "30", src: !accomplished ? goalStep1_1 : goalStep1_2 }) })));
                            } })), _jsx(Step, __assign({ transition: "scale" }, { children: function (_a) {
                                var accomplished = _a.accomplished;
                                return (_jsx(Tooltip, __assign({ title: "$".concat(goals[3].toLocaleString()), componentsProps: { tooltip: { sx: { bgcolor: "#7963ff", color: "white" } } } }, { children: _jsx("img", { width: "30", src: !accomplished ? goalStep1_1 : goalStep1_2 }) })));
                            } })), _jsx(Step, __assign({ transition: "scale" }, { children: function (_a) {
                                var accomplished = _a.accomplished;
                                return (_jsx(Tooltip, __assign({ title: "$".concat(goals[4].toLocaleString()), componentsProps: { tooltip: { sx: { bgcolor: "#7963ff", color: "white" } } } }, { children: _jsx("img", { width: "30", src: !accomplished ? goalStep1_1 : goalStep1_2 }) })));
                            } }))] })) }))] })));
}
