var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import MainMenu from './components/Menus/Menu';
import logo from './assets/img/logo.svg';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router, useLocation, useSearchParams } from 'react-router-dom';
import Login from './components/Login/Login';
import Cookies from 'universal-cookie';
import { theme } from './Theme';
import Loader from 'elements/Loader';
import { CiDesktop } from "react-icons/ci";
var cookies = new Cookies();
// Inicialización temporal de localStorage
// localStorage.setItem('page', '1');
// localStorage.setItem('plan_id_selected', '9');
// localStorage.setItem('company-token', 'F7FCQM8ZAVK49RUC');
// localStorage.setItem('api-key', 'ZBYFP4D3MCH0S41IAF6Q6VV8LG3QHKC4');
// localStorage.setItem('site-id-selected', '660edc5bda861798770873da');
var Site = function () {
    var userIsLogged = localStorage.getItem('ltkn') !== null;
    var searchParams = useSearchParams()[0];
    var invitation = searchParams.get("invitation");
    var email = searchParams.get("email");
    var workstation_code = searchParams.get("workstation_code");
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var location = useLocation();
    var isHome = location.pathname === "/" ? "home" : "";
    var _b = useState(window.innerWidth < 700), isMobile = _b[0], setIsMobile = _b[1];
    useEffect(function () {
        var handleResize = function () {
            setIsMobile(window.innerWidth < 700);
        };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(SnackbarProvider, __assign({ maxSnack: 3 }, { children: isMobile ? (_jsx("div", __assign({ className: 'noMobile' }, { children: _jsxs("div", __assign({ className: 'noMobileContent' }, { children: [_jsx(CiDesktop, { size: 80 }), _jsx("h2", { children: "Please use a desktop device to access this site" }), _jsx("span", { children: "Pennicart needs a bigger screen to work properly" })] })) }))) : (!userIsLogged ? (_jsx(Login, { setLoading: setLoading })) : (_jsx("div", __assign({ id: 'structure' }, { children: _jsxs("div", __assign({ id: "main-container" }, { children: [_jsxs("div", __assign({ id: 'header_container' }, { children: [_jsx("header", __assign({ "data-pc": "nav-component", className: "nav_padding" }, { children: _jsx("div", __assign({ className: "nav_container" }, { children: _jsx("div", __assign({ className: "nav_logo w-embed" }, { children: _jsx("a", __assign({ href: '/' }, { children: _jsx("img", { src: logo, alt: "Logo" }) })) })) })) })), _jsx("aside", __assign({ id: "menu" }, { children: _jsx(MainMenu, { setLoading: setLoading }) }))] })), _jsxs("div", __assign({ id: "main-content" }, { children: [_jsx(Loader, { loading: loading }), _jsx("div", __assign({ id: 'content' }, { children: _jsx(AppRoutes, { setLoading: setLoading }) }))] }))] })) })))) })) })));
};
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(Router, { children: _jsx(Site, {}) }));
