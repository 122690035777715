var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
export default function MenuButton(props) {
    var label = props.label, to = props.to, startIcon = props.startIcon, onClickCallback = props.onClickCallback, key = props.key;
    var location = useLocation();
    var checkActiveMenu = function (href) {
        return location.pathname.endsWith(href);
    };
    var styleMenu = {
        buttons: {
            textDecoration: 'none',
            padding: '10px 20px',
            width: '100%',
            justifyContent: 'start',
            color: '#637381',
            textTransform: 'none',
            '& svg': {
                width: '25px',
                height: '25px',
                marginRight: '10px',
                color: checkActiveMenu(to) ? '#ffffff' : '#637381',
            },
        },
    };
    return (_jsx(Button, __assign({ component: Link, startIcon: startIcon, to: to, sx: styleMenu.buttons, onClick: onClickCallback, className: checkActiveMenu(to) ? 'menu_button active_menu' : 'menu_button' }, { children: label }), key));
}
