var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import ChartJS from 'chart.js/auto'; // Importa ChartJS para el registro automático de componentes
import { Button } from '@mui/material';
// Registro de los componentes que se usarán
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
var LineChart = function (params) {
    var data = params.data;
    if (!data) {
        return null;
    }
    var options = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (tickValue, index, ticks) {
                        if (typeof tickValue === 'number') {
                            if (tickValue >= 1000) {
                                return tickValue / 1000 + 'k'; // Convertir a 'k'
                            }
                            else {
                                return tickValue;
                            }
                        }
                        return tickValue;
                    },
                },
                grid: {
                    display: false // Retira la grilla en el eje y
                }
            },
            x: {
                grid: {
                    display: false // Retira la grilla en el eje x
                }
            },
        },
        plugins: {
            legend: {
                display: false // Oculta las leyendas
            }
        }
    };
    var chart = _jsx(Line, { data: data, options: options, height: 100 });
    var _a = useState("users"), filter = _a[0], setFilter = _a[1];
    var handleFilterClick = function (newFilter) {
        setFilter(newFilter);
    };
    return _jsxs("div", __assign({ className: "planChart" }, { children: [_jsxs("div", __assign({ className: 'planChart_header' }, { children: [_jsxs("div", __assign({ className: 'planChart_buttons' }, { children: [_jsx(Button, __assign({ onClick: function () { return handleFilterClick("users"); }, className: filter === "users" ? "active" : "" }, { children: "Users" })), _jsx(Button, __assign({ onClick: function () { return handleFilterClick("revenue"); }, className: filter === "revenue" ? "active" : "" }, { children: "Monthly Recurring Revenue" }))] })), _jsx("div", __assign({ className: 'planChart_legends' }, { children: data.totals.map(function (total, index) {
                            return _jsxs("div", __assign({ className: 'planChart_legend' }, { children: [_jsx("div", __assign({ className: 'planChart_value' }, { children: total.total })), _jsxs("div", __assign({ className: 'planChart_label' }, { children: [_jsx("div", { className: 'planChart_legendCircle', style: { backgroundColor: total.color } }), _jsx("span", { children: total.plan_name })] }))] }), index);
                        }) }))] })), chart] }));
};
export default LineChart;
