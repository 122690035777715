var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ProjectConfig } from 'Global';
import { useSnackbar } from "notistack";
import ISwitch from "elements/ISwitch";
import ic_packages from 'icons/ic_packages.svg';
export default function PlanEditor(props) {
    /*=============================================
    VARIABLES FROM PROPS
    ============================================= */
    var setLoading = props.setLoading, openEditor = props.openEditor, setOpenEditor = props.setOpenEditor, plan_id = props.plan_id, loadList = props.loadList;
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    /*============================================= */
    var defaultValues = {
        plan_id: plan_id ? plan_id : "null",
        plan_name: "",
        status: false,
        plan_info: {
            title: "",
            short_description: "",
            description: "",
            prices: {
                monthly: 0,
                yearly: 0
            },
        },
    };
    var _b = useState(defaultValues), formValues = _b[0], setFormValues = _b[1];
    /*=======================================
    GET USER DETAILS IF USER_ID EXISTS
    =======================================*/
    useEffect(function () {
        if (plan_id) {
            axios.get(ProjectConfig.api_url + "/v2/plans/details", {
                params: {
                    plan_id: plan_id,
                },
            })
                .then(function (response) {
                console.log("formValues res", response.data);
                setFormValues(response.data);
            })
                .catch(function (err) {
                console.log(err.response.data);
            });
        }
        else {
            setFormValues(defaultValues);
        }
    }, [plan_id]);
    /*=======================================
    HANDLE INPUT CHANGE
    =======================================*/
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    /*=======================================
    SUBMIT FUNCTION
    =======================================*/
    var handleSubmit = function () {
        setLoading(true);
        var body = new FormData();
        body.append("formValues", JSON.stringify(formValues));
        console.log("formValues", formValues);
        axios.post(ProjectConfig.api_url + "/v2/plans/addedit", body, {
            headers: {
            // 
            },
        })
            .then(function (response) {
            console.log(response.data);
            if (response.data.status === "success") {
                enqueueSnackbar('Success', { variant: 'success' });
                setOpenEditor(false);
                loadList();
            }
            else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        })
            .catch(function (err) {
            console.log(err.response.data);
        }).
            finally(function () {
            setLoading(false);
        });
    };
    console.log("formValues", formValues);
    return (_jsx(_Fragment, { children: _jsxs(Dialog, __assign({ open: openEditor, onClose: function () { return setOpenEditor(false); }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", PaperProps: { sx: { borderRadius: "20px" } } }, { children: [_jsx("div", __assign({ className: "DialogContainer" }, { children: _jsxs("div", __assign({ className: "ModuleWrapper" }, { children: [_jsx("div", __assign({ className: "boxContainerHeader" }, { children: _jsxs("div", __assign({ className: "dialog_container_title" }, { children: [_jsxs("h2", __assign({ className: "dialog_title" }, { children: [_jsx("img", { src: ic_packages }), plan_id ? "Edit Package" : "Add Package"] })), _jsx("h3", __assign({ className: "dialog_subtitle" }, { children: "Package" }))] })) })), _jsxs("div", __assign({ className: "dialogFormContainer" }, { children: [_jsxs("div", __assign({ className: 'input_group' }, { children: [_jsx(TextField, { id: "plan_name", name: "plan_name", label: "Package Name", type: "text", required: true, fullWidth: true, value: formValues.plan_name, onChange: handleInputChange }), _jsx("h2", { children: "Prices" }), _jsx(TextField, { id: "monthly_price", name: "monthly_price", label: "Monthly Price", type: "number", required: true, fullWidth: true, value: formValues.plan_info.prices.monthly, onChange: function (e) {
                                                    setFormValues(__assign(__assign({}, formValues), { plan_info: __assign(__assign({}, formValues.plan_info), { prices: __assign(__assign({}, formValues.plan_info.prices), { monthly: e.target.value }) }) }));
                                                } }), _jsx(TextField, { id: "yearly_price", name: "yearly_price", label: "Annual Price", type: "number", required: true, fullWidth: true, value: formValues.plan_info.prices.yearly, onChange: function (e) {
                                                    setFormValues(__assign(__assign({}, formValues), { plan_info: __assign(__assign({}, formValues.plan_info), { prices: __assign(__assign({}, formValues.plan_info.prices), { yearly: e.target.value }) }) }));
                                                } }), _jsx(TextField, { id: "short_description", name: "short_description", label: "Short Description", type: "text", required: true, fullWidth: true, value: formValues.plan_info.short_description, onChange: function (e) {
                                                    setFormValues(__assign(__assign({}, formValues), { plan_info: __assign(__assign({}, formValues.plan_info), { short_description: e.target.value }) }));
                                                } }), _jsx(TextField, { id: "description", name: "description", label: "Description", type: "text", required: true, multiline: true, rows: 4, value: formValues.plan_info.description, onChange: function (e) {
                                                    setFormValues(__assign(__assign({}, formValues), { plan_info: __assign(__assign({}, formValues.plan_info), { description: e.target.value }) }));
                                                } })] })), _jsx(ISwitch, { label: "Status", checked: formValues.status, name: "modules", 
                                        //purple color
                                        switchColor: "#7B1FA2", onChange: function (e) {
                                            setFormValues(__assign(__assign({}, formValues), { status: e.target.checked }));
                                        } })] }))] })) })), _jsxs("div", __assign({ className: "dialogButtons" }, { children: [_jsx(Button, __assign({ onClick: handleSubmit, variant: "contained", className: "button_1" }, { children: "Save Changes" })), _jsx(Button, __assign({ onClick: function () { return setOpenEditor(false); }, variant: "contained", color: "secondary", className: "button_secondary" }, { children: "Cancel" }))] }))] })) }));
}
