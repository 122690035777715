var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Permissions } from 'Permissions';
import MenuButton from 'elements/MenuButton';
import 'assets/css/main.css';
export default function MainMenu(props) {
    var setLoading = props.setLoading;
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = Boolean(anchorEl);
    var role_id = Number(localStorage.getItem('role_id'));
    return (_jsx("div", __assign({ className: 'menu_button_container' }, { children: Permissions.map(function (permission, index) {
            if (permission.role_id === role_id) {
                return permission.routes.map(function (route, index) {
                    return (_jsx(React.Fragment, { children: route.components.map(function (item, index2) {
                            return _jsx(React.Fragment, { children: _jsx(MenuButton, { label: item.label, to: item.path, startIcon: item.icon, setLoading: setLoading }) }, index2);
                        }) }, index));
                });
            }
        }) })));
}
