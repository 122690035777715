import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Dashboard from 'components/Admin/Dashboard/Dashboard';
import { Route, Routes } from 'react-router-dom';
import Cookies from 'universal-cookie';
import CompanyList from 'components/Admin/Companies/CompanyList';
import Plans from 'components/Admin/Plans/PlanList';
import Stores from 'components/Admin/Stores/StoresList';
import StoreEditor from 'components/Admin/Stores/StoreEditor';
import Users from 'components/Admin/Users/UsersList';
import Orders from 'components/Admin/Orders/OrdersList';
import { Permissions } from './Permissions';
import MasterLogs from 'components/Admin/MasterLogs/MasterLogs';
import Logic from 'components/Companies/Logic/Logic';
var cookies = new Cookies();
var componentMap = {
    Dashboard: Dashboard,
    CompanyList: CompanyList,
    Plans: Plans,
    Stores: Stores,
    Users: Users,
    Orders: Orders,
    MasterLogs: MasterLogs
};
export default function AppRoutes(props) {
    var role_id = Number(localStorage.getItem('role_id'));
    var setLoading = props.setLoading;
    return (_jsx(_Fragment, { children: _jsxs(Routes, { children: [Permissions.map(function (permission, index) {
                    if (permission.role_id === role_id) {
                        return permission.routes.map(function (route, index) {
                            return route.components.map(function (item, index) {
                                var Component = componentMap[item.component];
                                return _jsx(Route, { path: item.path, element: _jsx(Component, { setLoading: setLoading }) });
                            });
                        });
                    }
                }), _jsx(Route, { path: "/", element: _jsx(Dashboard, { setLoading: setLoading }) }), _jsx(Route, { path: "/stores/:store_id", element: _jsx(StoreEditor, { setLoading: setLoading }) }), _jsx(Route, { path: "/logic", element: _jsx(Logic, { setLoading: setLoading }) })] }) }));
}
