var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import logo from '../../assets/img/logo.svg';
import Cookies from 'universal-cookie';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import SignUp from './SignUp';
import { useSearchParams } from 'react-router-dom';
import FormLogin from './FormLogin';
import FormCredentialsSent from './FormCredentialsSent';
import FormChangePass from './FormChangePass';
import FormForgotPass from './FormForgotPass';
var cookies = new Cookies();
export default withSnackbar(function SignIn(props) {
    var setLoading = props.setLoading;
    useEffect(function () {
        setLoading(false);
    }, []);
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    /*============================================= */
    var _b = useState(false), loadingButton = _b[0], setLoadingButton = _b[1];
    var _c = useState(false), openRegister = _c[0], setOpenRegister = _c[1];
    var _d = useState('login'), state = _d[0], setState = _d[1];
    var _e = useState(''), userEmail = _e[0], setUserEmail = _e[1];
    var _f = useState(''), email = _f[0], setEmail = _f[1];
    var _g = useState(''), code = _g[0], setCode = _g[1];
    var _h = useSearchParams(), searchParams = _h[0], setSearchParams = _h[1];
    useEffect(function () {
        var emailVar = localStorage.getItem('email') ? localStorage.getItem('email') : '';
        setLoading(false);
        setEmail(emailVar || searchParams.get('email') || '');
        setCode(searchParams.get('code') || '');
    }, []);
    useEffect(function () {
        if (email && code) {
            setState('changePass');
        }
    }, [code]);
    var loginDataDefault = {
        email: email,
        password: '',
    };
    var _j = useState(loginDataDefault), loginData = _j[0], setLoginData = _j[1];
    var sign_in = function () {
        axios
            .get(ProjectConfig.api_url + "/v2/users/auth", {
            params: {
                email: loginData.email,
                password: loginData.password,
            },
        })
            .then(function (response) {
            console.log(response.data);
            if (response.data.status === "success") {
                enqueueSnackbar(response.data.message, { variant: 'success' });
                var user_info = response.data.user_info;
                console.log(user_info);
                localStorage.setItem('ltkn', user_info.ltkn);
                localStorage.setItem('user_id', user_info.user_id);
                localStorage.setItem('user_name', user_info.user_name);
                localStorage.setItem('role_id', user_info.role_id);
                window.location.reload();
            }
            else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
                setLoadingButton(false);
            }
        })
            .catch(function (err) {
            console.log(err.data);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("header", __assign({ "data-pc": "nav-component", className: "nav_padding" }, { children: [_jsx("div", { "data-w-id": "68dd9a17-0234-0921-12f0-3adaa0a22869", className: "nav-cart_demo-background" }), _jsxs("div", __assign({ "nav-scroll": "true", className: "nav_container" }, { children: [_jsx("div", { className: "nav_container-background" }), _jsx("a", __assign({ href: "/", className: "nav_logo-link w-nav-brand", "aria-label": "home" }, { children: _jsx("div", __assign({ className: "nav_logo w-embed" }, { children: _jsx("img", { src: logo, alt: "", className: "nav_logo-image" }) })) }))] }))] })), _jsxs("div", __assign({ className: "section-gradient_component" }, { children: [_jsx("div", { className: "section-gradient_hero-1" }), _jsx("div", { className: "section-gradient_hero-2" }), _jsx("div", { className: "section-gradient_hero-3" }), _jsx("div", { className: "section-gradient_hero-5" })] })), _jsxs("div", __assign({ className: "loginContainer" }, { children: [state === "login" && (_jsx(FormLogin, { setLoading: setLoading, setState: setState, loginData: loginData, setEmail: setEmail, setLoginData: setLoginData, loadingButton: loadingButton, setLoadingButton: setLoadingButton, setOpenRegister: setOpenRegister, sign_in: sign_in })), state === "changePass" && (_jsx(FormChangePass, { loadingButton: loadingButton, setLoading: setLoading, setState: setState, email: email, code: code })), state === "forgotPass" && (_jsx(FormForgotPass, { loadingButton: loadingButton, setLoading: setLoading, setState: setState, email: email, setEmail: setEmail })), state === "credentialsSent" && (_jsx(FormCredentialsSent, { logo: logo, setState: setState, setEmail: setEmail, setLoginData: setLoginData, loginDataDefault: loginDataDefault }))] })), _jsx(SignUp, { openRegister: openRegister, setOpenRegister: setOpenRegister, loginData: loginData, setLoginData: setLoginData, sign_in: sign_in, setLoading: setLoading })] }));
});
