var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from 'Global';
export default function RefundEditor(props) {
    /*=============================================
    VARIABLES FROM PROPS
    ============================================= */
    var open = props.open, setOpen = props.setOpen, _a = props.item, item = _a === void 0 ? {} : _a, store_id = props.store_id, getPayments = props.getPayments, getDetails = props.getDetails;
    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    var _b = useSnackbar(), enqueueSnackbar = _b.enqueueSnackbar, closeSnackbar = _b.closeSnackbar;
    /*============================================= */
    var defaultValues = {
        refund: item.amount || "",
    };
    console.log("Item", item);
    var _c = useState(defaultValues), formValues = _c[0], setFormValues = _c[1];
    useEffect(function () {
        setFormValues({
            refund: item.amount || "",
        });
    }, [item]);
    /*=======================================
    HANDLE INPUT CHANGE
    =======================================*/
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    /*=======================================
    SUBMIT FUNCTION
    =======================================*/
    var handleSubmit = function () {
        setOpen(false); // close dialog
        var body = {
            store_subscription_id: item.store_subscription_id,
            refund_amount: formValues.refund,
            store_id: store_id
        };
        axios.post(ProjectConfig.api_url + "/v2/payments/refund", body, {
            headers: {},
        })
            .then(function (response) {
            console.log(response.data);
            if (response.data.status === "success") {
                enqueueSnackbar('Refund processed successfully', { variant: 'success' });
            }
            else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        })
            .catch(function (err) {
            console.log(err.response.data);
            enqueueSnackbar('Error processing refund', { variant: 'error' });
        })
            .finally(function () {
            getPayments();
            getDetails();
        });
    };
    return (_jsxs(Dialog, __assign({ open: open, onClose: function () { return setOpen(false); }, PaperProps: { sx: { borderRadius: "20px" } } }, { children: [_jsx("div", __assign({ className: "DialogContainer" }, { children: _jsxs("div", __assign({ className: "ModuleWrapper" }, { children: [_jsx("div", __assign({ className: "boxContainerHeader" }, { children: _jsxs("div", __assign({ className: "dialog_container_title" }, { children: [_jsx("h2", __assign({ className: "dialog_title" }, { children: "Refund" })), _jsx("h3", __assign({ className: "dialog_subtitle" }, { children: "Process Refund" }))] })) })), _jsx("div", __assign({ className: "dialogFormContainer" }, { children: _jsx("div", __assign({ className: 'input_group' }, { children: _jsx(TextField, { id: "refund", name: "refund", label: "Refund Amount", type: "number", required: true, fullWidth: true, value: formValues.refund, onChange: handleInputChange }) })) }))] })) })), _jsxs("div", __assign({ className: "dialogButtons" }, { children: [_jsx(Button, __assign({ onClick: handleSubmit, variant: "contained", className: "button_1" }, { children: "Process Refund" })), _jsx(Button, __assign({ onClick: function () { return setOpen(false); }, variant: "contained", color: "secondary", className: "button_secondary" }, { children: "Cancel" }))] }))] })));
}
