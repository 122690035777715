import { jsx as _jsx } from "react/jsx-runtime";
import SpeedIcon from '@mui/icons-material/Speed';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PersonIcon from '@mui/icons-material/Person';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarIcon from '@mui/icons-material/Star';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
export var Permissions = [
    {
        role_id: 1,
        routes: [
            {
                category: 'Administrador',
                components: [
                    {
                        path: 'report',
                        component: 'Dashboard',
                        //icon: <SpeedIcon />,
                        label: 'Report',
                    },
                    {
                        path: 'stores',
                        component: 'Stores',
                        label: 'Stores',
                    },
                    {
                        path: 'orders',
                        component: 'Orders',
                        label: 'Order Log',
                    },
                    {
                        path: 'master-log',
                        component: 'MasterLogs',
                        label: 'Master Log',
                    },
                    {
                        path: 'packages',
                        component: 'Plans',
                        label: 'Packages',
                    },
                    {
                        path: 'admin-roles',
                        component: 'Users',
                        label: 'Admin Roles',
                    },
                ]
            },
            {
                category: 'General',
                components: []
            }
        ]
    },
    {
        role_id: 2,
        routes: [
            {
                category: 'Usuario',
                components: [
                    {
                        path: 'dashboard',
                        component: 'Dashboard',
                        icon: _jsx(SpeedIcon, {}),
                        label: 'Dashboard',
                    },
                ]
            },
            {
                category: 'General',
                components: [
                    // {
                    //     path: '/clients', 
                    //     component: 'Clients',
                    //     icon: <SupervisorAccount />,
                    //     label: 'Clientes',
                    // },
                    {
                        path: '/users',
                        component: 'Users',
                        icon: _jsx(PersonIcon, {}),
                        label: 'Usuarios',
                    },
                    {
                        path: '/sync',
                        component: 'Sync',
                        icon: _jsx(CloudSyncIcon, {}),
                        label: 'Sincronizar',
                    },
                ]
            },
            {
                category: 'Inventario',
                components: [
                    {
                        path: '/warehouses',
                        component: 'Warehouses',
                        icon: _jsx(WarehouseIcon, {}),
                        label: 'Bodegas',
                    },
                    {
                        path: '/categories',
                        component: 'Categories',
                        icon: _jsx(WidgetsIcon, {}),
                        label: 'Categorías',
                    },
                    {
                        path: '/suppliers',
                        component: 'Suppliers',
                        icon: _jsx(LocalShippingIcon, {}),
                        label: 'Proveedores',
                    },
                    {
                        path: '/brands',
                        component: 'Brands',
                        icon: _jsx(StarIcon, {}),
                        label: 'Marcas',
                    },
                    {
                        path: '/reports',
                        component: 'Reports',
                        icon: _jsx(AssessmentOutlinedIcon, {}),
                        label: 'Reportes',
                    },
                ]
            },
            {
                category: 'Pedidos',
                components: [
                    {
                        path: '/clients',
                        component: 'Clients',
                        icon: _jsx(ReceiptIcon, {}),
                        label: 'Clientes',
                    },
                ]
            },
        ]
    },
];
