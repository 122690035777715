var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField, } from '@mui/material';
import Box from 'elements/Box';
import { ProjectConfig } from 'Global';
export default function FormChangePass(props) {
    var loadingButton = props.loadingButton, logo = props.logo, setLoading = props.setLoading, setState = props.setState, email = props.email, code = props.code;
    /*================================================
    SNACKBAR FUNCTION FOR NOTIFICATIONS
    ================================================*/
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    /*============================================= */
    var _b = useState(''), newPassword = _b[0], setNewPassword = _b[1];
    var _c = useState(''), confirmPassword = _c[0], setConfirmPassword = _c[1];
    /*================================================
    CHANGE PASSWORD (SUBMIT)
    ================================================*/
    var handleSubmit = function (event) {
        if (newPassword !== confirmPassword) {
            enqueueSnackbar("Passwords do not match", { variant: 'error' });
            return;
        }
        setLoading(true);
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "change_password",
                email: email,
                code: code,
                newPassword: newPassword,
                confirmPassword: confirmPassword,
            },
        })
            .then(function (response) {
            if (response.data.status === "success") {
                enqueueSnackbar(response.data.message, { variant: 'success' });
                setState('login');
            }
            else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        })
            .catch(function (error) {
            enqueueSnackbar(error.toString(), { variant: 'error' });
        })
            .finally(function () {
            setLoading(false);
        });
    };
    /*================================================
    ================================================*/
    return (_jsxs(Box, __assign({ id: 'loginBox' }, { children: [_jsx("img", { className: 'loginLogo', src: logo }), _jsx("p", { children: "Ingresa una nueva contrase\u00F1a para tu cuenta" }), _jsxs("form", __assign({ onSubmit: handleSubmit, className: 'form' }, { children: [_jsx(TextField, { required: true, fullWidth: true, name: "newPassword", label: "Nueva contrase\u00F1a", type: "password", id: "newPassword", value: newPassword, onChange: function (e) { return setNewPassword(e.target.value); } }), _jsx(TextField, { required: true, fullWidth: true, name: "confirmPassword", label: "Confirma nueva contrase\u00F1a", type: "password", id: "confirmPassword", value: confirmPassword, onChange: function (e) { return setConfirmPassword(e.target.value); }, error: newPassword !== confirmPassword && confirmPassword !== '' ? true : false }), _jsx(LoadingButton, __assign({ size: "small", type: "submit", loading: loadingButton, fullWidth: true, variant: "contained", sx: { fontSize: '13px', padding: '10px 0px' } }, { children: "Cambiar contrase\u00F1a" }))] }))] })));
}
