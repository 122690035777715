var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Box = function (props) {
    var className = props.className ? " " + props.className : "";
    var id = props.id ? props.id : "";
    var style = props.style ? props.style : {};
    var box_header = props.title ? _jsxs("div", __assign({ className: 'box_header' }, { children: [_jsx("h2", __assign({ className: 'card_title' }, { children: props.title })), _jsx("span", __assign({ className: 'card_subtitle' }, { children: props.subtitle }))] })) : null;
    return (_jsx("div", __assign({ id: id, className: "box" + className, style: style }, { children: _jsxs("div", __assign({ className: "box_children" }, { children: [box_header, _jsx("div", __assign({ className: "box_content" }, { children: props.children }))] })) }), props.key));
};
export default Box;
