var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'elements/Box';
import { Button, Dialog, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectConfig } from 'Global';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';
import 'assets/css/main.css';
import CompanyEditor from './CompanyEditor';
import UsersList from '../Users/UsersList';
export default function CompanyList(props) {
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    var setLoading = props.setLoading;
    useEffect(function () {
        setLoading(false);
    }, []);
    var _b = useState([]), company_list = _b[0], setUserList = _b[1];
    var _c = useState(0), total_pages = _c[0], setTotalPages = _c[1];
    var queryParams = new URLSearchParams(window.location.search);
    var currentPage = parseInt(localStorage.getItem('page') || "1");
    var _d = useState(currentPage), page = _d[0], setPage = _d[1];
    var category = queryParams.get('category');
    var _e = useState(0), refreshList = _e[0], setRefreshList = _e[1];
    var _f = useState(false), openEditor = _f[0], setOpenEditor = _f[1];
    var _g = useState(false), openUserList = _g[0], setOpenUserList = _g[1];
    useEffect(function () {
        setLoading(true);
        loadList();
    }, [page]);
    /*=========================================================
    LOAD LIST
    =========================================================*/
    var loadList = function () {
        setLoading(true);
        axios.get(ProjectConfig.api_url + "/companies/list", {
            params: {
                page: page,
                role_id: 1,
            }
        })
            .then(function (response) {
            console.log("Company List", response.data);
            setUserList(response.data.list);
            setTotalPages(response.data.total_pages);
        })
            .catch(function (err) {
            console.log(err.response.data);
        })
            .finally(function () {
            setLoading(false);
        });
        localStorage.setItem('page', page.toString());
    };
    /*=========================================================*/
    /*=========================================================
    DELETE ITEM
    =========================================================*/
    var handleDelete = function (company_id) {
        setActiveItem(company_id);
        setOpenAlert(true);
    };
    var deleteItem = function () {
        var body = {
            company_id: activeItem
        };
        axios.post(ProjectConfig.api_url + "/companies/deleteItem", body)
            .then(function (response) {
            console.log(response.data);
            setRefreshList(refreshList + 1);
            if (response.data.status === "success") {
                enqueueSnackbar('Empresa eliminada con éxito', { variant: 'success' });
                loadList();
            }
        })
            .catch(function (err) {
            console.log(err.message);
        })
            .then(function () {
            setOpenAlert(false);
        });
    };
    /*=========================================================*/
    // call global snackbar
    var _h = useState(false), openAlert = _h[0], setOpenAlert = _h[1];
    var _j = useState(null), activeItem = _j[0], setActiveItem = _j[1];
    return (_jsxs("div", __assign({ id: 'CompaniesList', className: 'ModuleContainer' }, { children: [_jsxs("div", __assign({ className: "ModuleWrapper" }, { children: [_jsxs("div", __assign({ className: "boxContainerHeader" }, { children: [_jsxs("div", __assign({ className: "boxContainerTitle" }, { children: [_jsx("h2", __assign({ className: "h2_title" }, { children: "Empresas" })), _jsx("span", __assign({ className: "h2_subtitle" }, { children: "Lista de empresas registradas" }))] })), _jsx("div", __assign({ className: "boxContainerActions" }, { children: _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () {
                                        setActiveItem(null);
                                        setOpenEditor(true);
                                    }, startIcon: _jsx(AddIcon, {}) }, { children: "Crear empresa" })) }))] })), _jsx("div", __assign({ id: 'CompaniesListIn' }, { children: _jsxs(Box, __assign({ id: 'AdminList', className: 'box100' }, { children: [_jsx(TableContainer, { children: _jsxs(Table, __assign({ "aria-label": "sticky table" }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "NOMBRE / EMAIL" })), _jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "NIT" })), _jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "Direcci\u00F3n" })), _jsx(TableCell, { sx: { color: '#a8a8a8', borderBottom: "none" } })] }) }), _jsx(TableBody, { children: company_list.map(function (company, i) { return (_jsxs(TableRow, __assign({ hover: true, role: "checkbox" }, { children: [_jsxs(TableCell, { children: [_jsx("span", __assign({ className: 'flex_title' }, { children: company.company_name })), _jsx("span", __assign({ className: 'flex_subtitle' }, { children: company.email }))] }), _jsx(TableCell, { children: company.nit }), _jsx(TableCell, { children: company.address }), _jsxs(TableCell, __assign({ align: "right" }, { children: [_jsx(Button
                                                                // component={Link}
                                                                // to={`edit/${company.company_id}`}
                                                                , __assign({ 
                                                                    // component={Link}
                                                                    // to={`edit/${company.company_id}`}
                                                                    size: "small", variant: "contained", color: "primary", style: { "marginRight": "10px" }, startIcon: _jsx(ModeEditIcon, {}), onClick: function () {
                                                                        setActiveItem(company.company_id);
                                                                        setOpenEditor(true);
                                                                    } }, { children: "Editar" })), _jsx(Button, __assign({ size: "small", variant: "contained", color: "secondary", startIcon: _jsx(ModeEditIcon, {}), onClick: function () {
                                                                        setActiveItem(company.company_id);
                                                                        setOpenUserList(true);
                                                                    } }, { children: "Usuarios" })), _jsx(IconButton, __assign({ "aria-label": "delete", color: "primary", onClick: function () { return handleDelete(company.company_id); } }, { children: _jsx(DeleteOutlineOutlinedIcon, {}) }))] }))] }), i)); }) })] })) }), _jsx(Pagination, { count: total_pages, page: page, onChange: function (event, value) {
                                        setPage(value);
                                    }, color: "primary", style: { "marginTop": "20px", "alignSelf": "center" } })] })) }))] })), _jsx(AlertDialog, { open: openAlert, setOpen: setOpenAlert, subtitle: "\u00BFEst\u00E1 seguro que desea eliminar este usuario?", send: deleteItem }), _jsx(CompanyEditor, { company_id: activeItem, openEditor: openEditor, setOpenEditor: setOpenEditor, loadList: loadList, setLoading: setLoading }), _jsx(Dialog, __assign({ open: openUserList, onClose: function () { return setOpenUserList(false); }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsx("div", __assign({ className: "DialogContainer" }, { children: _jsx(UsersList, { company_id: activeItem, setLoading: setLoading, is_popup: true }) })) }))] })));
}
;
