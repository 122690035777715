var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from "axios";
import { useEffect, useState } from "react";
import { ProjectConfig } from "Global";
import Cookies from "universal-cookie";
import { Button, Divider, FormControl, FormControlLabel, Menu, MenuItem, Radio, RadioGroup } from "@mui/material";
import GoalProgress from "./components/GoalProgress";
import TopAccounts from "./components/TopAccounts";
import PlanChart from "./components/PlanChart";
import BoxIndicator from "./components/indicators/BoxIndicator";
import ic_tune from 'icons/ic_tune.svg';
var cookies = new Cookies();
export default function Home(props) {
    var _this = this;
    var setLoading = props.setLoading;
    /*=============================================
    GET DASHBOARD DATA
    =============================================*/
    var _a = useState({
        indicators: [],
        goalProgress: [],
        topAccounts: [],
        planChart: {
            labels: [],
            datasets: [],
            totals: []
        }
    }), dashboardData = _a[0], setDashboardData = _a[1];
    /*=============================================
    FILTERS
    =============================================*/
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var handleFilters = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var _c = useState("Last 90 Days"), filter = _c[0], setFilter = _c[1];
    /*=============================================
    GET DASHBOARD DATA
    =============================================*/
    var getDashboardData = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setLoading(true);
            axios.get(ProjectConfig.api_url + "/v2/dashboard", {
                headers: {
                    'ltkn': localStorage.getItem('ltkn')
                },
                params: {
                    filter: filter
                }
            })
                .then(function (response) {
                console.log("dashboard data ", response.data);
                setDashboardData(response.data);
            })
                .catch(function (err) {
                console.log(err.response.data);
            })
                .finally(function () {
                setLoading(false);
            });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        getDashboardData();
    }, [filter]);
    return (_jsx("div", __assign({ id: "Dashboard", className: "ModuleContainer" }, { children: _jsxs("div", __assign({ className: "ModuleWrapper" }, { children: [_jsxs("div", __assign({ className: "ComponentHeader" }, { children: [_jsx("div", __assign({ className: "ComponentTitle" }, { children: _jsx("h1", { children: "Report" }) })), _jsxs("div", __assign({ className: "ComponentButtons" }, { children: [_jsx(Button, __assign({ endIcon: _jsx("img", { src: ic_tune, alt: "Filter" }), onClick: handleFilters, style: { color: '#424242', padding: '5px 20px', fontWeight: '400 !important' }, className: 'filtersButton' }, { children: "Filter" })), _jsxs(Menu, __assign({ id: "simple-menu", anchorEl: anchorEl, keepMounted: true, className: "filtersMenu", open: Boolean(anchorEl), onClose: function () { return setAnchorEl(null); } }, { children: [_jsx("h3", __assign({ className: "filtersTitle" }, { children: "Date Range" })), _jsx(FormControl, { children: _jsx(RadioGroup, __assign({ "aria-labelledby": "demo-radio-buttons-group-label", defaultValue: "female", name: "radio-buttons-group" }, { children: [
                                                    "Today",
                                                    "Yesterday",
                                                    "Last 24 Hours",
                                                    "Last 7 Days",
                                                    "Week To Date",
                                                    "Last 30 Days",
                                                    "Last Month",
                                                    "Month To Date",
                                                    "Last 90 Days",
                                                    "Quarter To Date",
                                                    "Last 12 Months",
                                                    "Year To Date",
                                                    "All Time"
                                                ].map(function (value, index) { return (_jsx(MenuItem, __assign({ className: "menuItem", value: value, onClick: function () {
                                                        setAnchorEl(null);
                                                        setFilter(value);
                                                    } }, { children: _jsx(FormControlLabel, { value: value, control: _jsx(Radio, { size: "small" }), label: value, checked: filter === value }) }), index)); }) })) })] }))] }))] })), _jsxs("div", __assign({ id: "DashboardIn" }, { children: [_jsx("div", __assign({ id: "dashboardIndicators" }, { children: dashboardData.indicators.map(function (indicator, i) { return (_jsx(BoxIndicator, { title: indicator.title, indicator: indicator.value, percentageLabel: indicator.percentage, icon: "up" })); }) })), _jsx(GoalProgress, { value: dashboardData.goalProgress }), _jsx(PlanChart, { data: dashboardData.planChart }), _jsx(Divider, { color: "#7963F", sx: { borderBottomWidth: 2 } }), _jsx(TopAccounts, {})] }))] })) })));
}
