var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ProjectConfig } from 'Global';
import { useSnackbar } from 'notistack';
import 'assets/css/main.css';
import ListPagination from 'elements/ListPagination';
import FiltersByTable from 'elements/Filters/FiltersByTable';
export default function MasterLogs(props) {
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    var setLoading = props.setLoading;
    useEffect(function () {
        setLoading(false);
    }, []);
    var _b = useState([]), list = _b[0], setList = _b[1];
    var _c = useState({
        total_pages: 1,
        total_items: 0,
    }), pagination = _c[0], setPagination = _c[1];
    var queryParams = new URLSearchParams(window.location.search);
    var currentPage = parseInt(localStorage.getItem('page') || "1");
    var _d = useState(currentPage), page = _d[0], setPage = _d[1];
    var category = queryParams.get('category');
    var _e = useState(0), refreshList = _e[0], setRefreshList = _e[1];
    var _f = useState(false), openEditor = _f[0], setOpenEditor = _f[1];
    var _g = useState([]), filtersList = _g[0], setFiltersList = _g[1];
    var _h = useState([]), filtersValues = _h[0], setFiltersValues = _h[1];
    /*=========================================================
    GET ACTIVITY LOGS
    =========================================================*/
    var _j = useState([]), activityLogs = _j[0], setActivityLogs = _j[1];
    useEffect(function () {
        loadList();
    }, [page, filtersValues]);
    var loadList = function (download) {
        if (download === void 0) { download = false; }
        setLoading(true);
        axios.get(ProjectConfig.api_url + "/v2/stores/activity_logs", {
            params: {
                filters: filtersValues
            },
        })
            .then(function (response) {
            console.log("activityLogs res", response.data);
            setActivityLogs(response.data.list);
            setPagination(response.data.pagination);
            setFiltersList(response.data.filtersList);
            if (download) {
                var url = window.URL.createObjectURL(new Blob([response.data.csv]));
                var link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'activity_logs.csv');
                document.body.appendChild(link);
                link.click();
            }
        })
            .catch(function (err) {
            console.log(err.response.data);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    return (_jsx("div", __assign({ id: 'MasterLog', className: 'ModuleContainer' }, { children: _jsxs("div", __assign({ className: "ModuleWrapper" }, { children: [_jsxs("div", __assign({ className: "boxContainerHeader" }, { children: [_jsx("div", __assign({ className: "boxContainerTitle" }, { children: _jsx("h1", { children: "Master Log" }) })), _jsxs("div", __assign({ className: "componentTools" }, { children: [_jsx(FiltersByTable, { filters: filtersList, setFilters: setFiltersValues, searchBy: ['log'] }), _jsx("div", { children: _jsx(Button, __assign({ variant: "contained", className: "button_3", size: "small", onClick: function () {
                                            loadList(true);
                                        } }, { children: "Export CSV" })) })] }))] })), _jsx("div", __assign({ className: 'boxContainerIn' }, { children: _jsxs("div", { children: [_jsx(TableContainer, __assign({ sx: { width: "100%" } }, { children: _jsxs(Table, __assign({ sx: { width: "100%" } }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "Date" })), _jsx(TableCell, __assign({ sx: { color: '#a8a8a8', borderBottom: "none" } }, { children: "Log" }))] }) }), _jsx(TableBody, { children: activityLogs.map(function (log, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: log.date }), _jsx(TableCell, { children: log.log })] }, index)); }) })] })) })), _jsx(ListPagination, { pagination: pagination, page: page, onChange: function (event, value) {
                                    setPage(value);
                                }, label: "Logs", style: { marginTop: "20px", alignSelf: "center" } })] }) }))] })) })));
}
;
