var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ic_empty from 'icons/ic_empty.svg';
export default function EmptyElement(props) {
    var title = props.title, subtitle = props.subtitle;
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "empty_element" }, { children: _jsxs("div", __assign({ className: "empty_element_content" }, { children: [_jsx("img", { src: ic_empty, alt: "", className: "ic_empty" }), _jsx("span", __assign({ className: "empty_element_title" }, { children: title })), _jsx("span", __assign({ className: "empty_element_subtitle" }, { children: subtitle }))] })) })) }));
}
