var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
export default function BoxIndicator(props) {
    var title = props.title, indicator = props.indicator, percentageLabel = props.percentageLabel, icon = props.icon;
    var iconSymbol = null;
    if (icon) {
        iconSymbol = icon === "up" ?
            _jsx(TrendingUpIcon, { sx: { fontSize: 16 } })
            :
                _jsx(TrendingDownIcon, { sx: { fontSize: 16 } });
    }
    return (_jsx("div", __assign({ className: "boxIndicator" }, { children: _jsxs("div", __assign({ className: "boxIndicatorContent" }, { children: [_jsx("h2", { children: title }), _jsxs("div", __assign({ className: "boxIndicatorContentInner" }, { children: [_jsx("span", __assign({ className: "indicatorValue" }, { children: indicator })), _jsxs("span", __assign({ className: "percentageLabel" }, { children: [percentageLabel, iconSymbol] }))] }))] })) })));
}
